<template>
  <VAlert
    :value="isOpen"
    color="bloak-blue"
    :class="[
      'global-notice my-0',
      { 'sm-and-down': ['xs', 'sm'].includes($mq) },
    ]"
    transition="fade-slow"
    mode="in-out"
  >
    <VLayout row justify-center>
      <VFlex xs10 sm8 lg6 class="white--text">
        <BaseSiteContent
          tag="h2"
          tag-class="header-2"
          content-key="covid_alert_title"
          default-content="An Update from Blue Oak"
        />
        <BaseSiteContent
          tag="p"
          content-key="covid_alert_text"
          default-content="Based on recommendations by local &amp; state governments, We are now allowed to have outdoor dining for our patrons. Cash payments are now accepted and preorders are no longer required. If you enter the building to use the restrooms please wear a mask for the safety of our guests as well as our staff.

We greatly appreciate your support during this time. Please be patient with us as we work to serve the community and look out for our staff."
        />
        <BaseButtonOutline
          class="ml-0"
          outline-color="white"
          @click="toggleGlobalNotice"
          >Hide this Update</BaseButtonOutline
        >
        <component
          :is="'BaseButton'"
          v-if="getSiteContents.covid_alert_btn_text"
          color="bloak-piggy-pink"
          class="ml-0"
          :to="getSiteContents.covid_alert_btn_route"
          :href="getSiteContents.covid_alert_btn_link"
        >
          {{ getSiteContents.covid_alert_btn_text }}
        </component>
        <BaseButton
          v-else
          color="bloak-piggy-pink"
          class="ml-0"
          href="https://shop.blueoakbbq.com/collections/merchandise"
          >Buy Some Merch</BaseButton
        >
      </VFlex>
    </VLayout>
  </VAlert>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'BlueOakGlobalNotice',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('nav', ['getGlobalNoticeState']),
    ...mapGetters('site', ['getSiteContents']),
    isOpen: {
      get() {
        return this.getGlobalNoticeState
      },
      set() {
        this.toggleGlobalNotice()
      },
    }, // isOpen
  }, // computed
  methods: {
    ...mapActions('nav', ['toggleGlobalNotice']),
  },
}
</script>

<style lang="scss" scoped>
.v-alert.global-notice {
  height: auto;
  min-height: 60vh;

  &.sm-and-down {
    padding-top: 120px;
    padding-bottom: 60px;
  }

  ::v-deep p {
    font-size: 20px;
    letter-spacing: 0.012em;
  }
}

.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity 1s;
}
.fade-slow-enter,
.fade-slow-leave-active {
  opacity: 0;
}
.fade-slow-enter-active {
  transition-delay: 1s;
}
</style>
